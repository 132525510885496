/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
//import './bootstrap';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
require('jquery-ui-bundle');
//require('webpack-jquery-ui');
//require('webpack-jquery-ui/draggable');
//require('jquery-ui-dist/jquery-ui');

(window).jQuery = $;
require('jquery-ui-touch-punch');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

//import Translator from 'bazinga-translator';
//Translator = require('bazinga-translator');

$(document).ready(function() {
    
    $(document).on('click', 'header .hamburger', function(){
	$('header ul.lang').hide();
	$('header nav').toggle();
	return false;
    });
    
    $(document).on('click', '.wrapper .container .start a.close', function(){
	$('#layer-bg-3').hide();
	$('.wrapper .container .start').removeClass('layer-over-1');
	$('.wrapper .container .start p').removeClass('move-right');
	$('.wrapper .container .start p span.title-1').show();
	$('.wrapper .container .start p span.title-2').hide();
	$('.wrapper .container .start a.close').hide();
	return false;
    });
    
    $(document).on('click', '.start ul.basic-operations-logos li a span.button', function(){
	$('#layer-bg-1').show();
	$('.wrapper .container .start p.wait').show();
	
	$('.start ul.basic-operations li a.name').hide();
	
	$(this).closest('ul').find('li a span.button').each(function(){
	    $(this).html('');
	});
	
	var l_img = document.createElement("IMG");
	l_img.src = $(this).data('img');
	$(this).prepend(l_img);
	
	$('.start ul.basic-operations li a.name.' + $(this).data('type')).show();

	
	/*
	$('#layer-bg-3').show();
	$('.wrapper .container .start').addClass('layer-over-1');
	$('.wrapper .container .start p').addClass('move-right');
	$('.wrapper .container .start p span.title-1').hide();
	$('.wrapper .container .start p span.title-2').show();
	$('.wrapper .container .start a.close').show();
	
	$(this).closest('ul.basic-operations-logos').find('span').html('');
	
	var l_img = document.createElement("IMG");
	l_img.src = $(this).data('img');
	$(this).prepend(l_img);
	
	$('.start ul.basic-operations li a.name').hide();
	$('.start ul.basic-operations li a.name.' + $(this).data('type')).show();
	
	return false;
	*/
    });
    
    
    
    $(document).on('click', '.nlsumbit', function(){
        
        var obj = $(this).closest('div.newsletter');
        var l_email = $.trim($(obj).find('#email').val());
    
        if(!l_email){
            alert(msg_email_empty);
            return false;
        }
        if(l_email.indexOf('@') == -1 || l_email.indexOf(' ') >= 0){
            alert(msg_email_invalid);
            return false;
        }
        
        /*
        if(!($('#nlcb').is(':checked'))){
            alert('Obavezno je pristati na uvjete korištenja!');
            return false;
        }
        */
    
    
        var $formData = new FormData();

        $formData.append('email', $(obj).find('#email').val());
        $formData.append('agreed', 1);
        
        $.ajax({
            type: "POST",
            url: route_newsletterAdd,
            data: $formData,
            contentType: false,
            processData: false,
            error: function (jqXHR, exception) {
            },
        }).done(function (data) {
            if(data.status){
                $(obj).find('#email').val('');
                $(obj).find('#nlcb').prop('checked', false);
                alert(msg_success);
            }
        });
            
        return false;
    });

    
    
});
